@tailwind base;
@tailwind components;
@tailwind utilities;
.App {
  text-align: center;
}

.App-logo {
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.regbg{
  background-image: url("../public/regbg.jpg");
  background-size:cover ;
}

.herobg{
  background-image: url("../public/herobg.jpg");
  background-size:cover ;
}
.bg1{
    background-image: url("../public/p1.jpg");
    background-size:cover ;
}
.bg2{
    background-image: url("../public/p2.jpg");
    background-size:cover ;
}

.bg3{
    background-image: url("../public/p3.jpg");
    background-size:cover ;
    
}

.bg4{
    background-image: url("../public/p4.jpg");
    background-size:cover ;
}

.bg5{
    background-image: url("../public/p5.jpg");
    background-size:cover ;
}

